import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, distinctUntilChanged, filter, map, Observable, ReplaySubject, Subject, takeUntil} from 'rxjs';
import {
  AccessTokenResult,
  AccessTokenResultError,
  ContextInfo,
  ContextInfoProperty,
  EmitAccessTokenRequest,
  EmitCloseRequest,
  EmitOutgoingIntentRequest,
  IngoingIntent,
  OutgoingIntentResult,
  PropertyKey
} from '../app-view/api/v1/types';
import {NGXLogger} from 'ngx-logger';
import {NavigationEnd, NavigationStart, Router, RouterEvent} from '@angular/router';


interface ShellProxy {
  dispatchIntent: (ingoingIntent: IngoingIntent) => void,
  dispatchContextInfo: (contextInfo: ContextInfo) => void,
}

interface ShellIntegrationProxy {
  requestAccessToken(
    accessTokenRequest: EmitAccessTokenRequest,
  ): AccessTokenResult,

  handleOutgoingIntentRequest(
    outgoingIntentRequest: EmitOutgoingIntentRequest,
  ): OutgoingIntentResult,

  requestClose(
    object: Object,
  ): void,

  connected(
    object: Object,
  ): void,
}


@Injectable({
  providedIn: 'root'
})
export class EmbeddedService
  implements OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();

  private readonly embedded_ = new BehaviorSubject<boolean>(false);

  private readonly _contextInfo$ = new BehaviorSubject<Map<PropertyKey, ContextInfoProperty>>(new Map());

  private shellProxy?: ShellProxy;

  // TODI: Ersetzen durch ein Subject das nur beim ersten Subscribe die gepufferten Werte ausgibt
  readonly ingoingIntent$ = new ReplaySubject<IngoingIntent>();

  readonly accessTokenListeners = new Array<(accessToken: string) => void>();

  constructor(
    private logger: NGXLogger,
    private router: Router,
  ) {
    // INFO: set embedded
    this.router.events
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(event => {
          if (event instanceof NavigationEnd) {
            return true;
          }
          if (event instanceof NavigationStart) {
            return true;
          }
          return false;
        }),
        map(event => event as RouterEvent),
        map(event => event.url),
        map(url => url.startsWith('/embedded')),
        distinctUntilChanged(),
      )
      .subscribe(isEmbedded => {
        this.embedded_.next(isEmbedded);
        this.toggleShellProxy(isEmbedded);

        this.logger.trace('Changed embedded, isEmbedded=' + isEmbedded);
      });

    // INFO: init toggle to false
    this.toggleShellProxy(false);
  }

  ngOnDestroy(): void {
    // INFO: complete all subscriptions
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    // INFO: complete ingoing intents
    this.ingoingIntent$.complete();
    // INFO: remove shell proxy on destroy
    this.removeShellProxy();
  }

  get contextInfo(): Map<PropertyKey, ContextInfoProperty> {
    return new Map(this._contextInfo$.value);
  }

  get contextInfo$(): Observable<Map<PropertyKey, ContextInfoProperty>> {
    return this._contextInfo$.asObservable();
  }

  get embedded$(): Observable<boolean> {
    return this.embedded_.asObservable();
  }

  get embedded(): boolean {
    return this.embedded_.value;
  }

  public getAccessToken(): string {
    const shellIntegrationProxy = this.getShellIntegrationProxy();
    if (shellIntegrationProxy) {
      try {
        const accessTokenRequest: EmitAccessTokenRequest = {
          appFqn: 'system:embedded',
          accessTokenRequest: {},
          resultCallback: () => {
            // no-op
          }
        };

        const result = shellIntegrationProxy.requestAccessToken(accessTokenRequest);
        if (result && result.success && result.accessToken) {
          for (const accessTokenListenersFunction of this.accessTokenListeners) {
            accessTokenListenersFunction(result.accessToken);
          }
          return result.accessToken;
        }
      } catch (error) {
        this.logger.warn('Embedded: Error during request access request', error);
      }
    }
    return '';
  }

  public addAccessTokenListener(
    handler: (accessToken: string) => void,
  ): void {
    this.accessTokenListeners.push(handler);
  }

  private getShellIntegrationProxy(): ShellIntegrationProxy | undefined {
    try {
      const shellIntegrationProxy = (<any>window).shellintegration_v0_ShellIntegrationProxy;
      if (!shellIntegrationProxy) {
        this.logger.warn('Could not find shell integration proxy');
      }

      return shellIntegrationProxy;
    } catch (e) {
      this.logger.warn('Could not find shell integration proxy', e);

      return undefined;
    }
  }

  private toggleShellProxy(
    isEmbedded: boolean
  ): void {
    if (isEmbedded) {
      this.registerShellProxy();
    } else {
      this.removeShellProxy();
    }
  }

  private removeShellProxy(): void {
    if (this.shellProxy) {
      // INFO: 'invalidate' current, prevent old references usage
      this.shellProxy.dispatchIntent = () => {
      };
      (<any>window).shellintegration_v0_ShellProxy = undefined;
      this.shellProxy = undefined;

      this.logger.trace('Removed \'window.shellintegration_v0_ShellProxy\'');
    }
  }

  private registerShellProxy(): void {
    this.removeShellProxy();
    this.shellProxy = {
      dispatchIntent: ingoingIntent => this.dispatchIntentEmbedded(ingoingIntent),
      dispatchContextInfo: contextInfo => this.dispatchContextInfoEmbedded(contextInfo),
    };
    (<any>window).shellintegration_v0_ShellProxy = this.shellProxy;

    this.getShellIntegrationProxy()?.connected({});

    this.logger.trace('Added \'window.shellintegration_v0_ShellProxy\'');
  }

  private dispatchIntentEmbedded(
    ingoingIntent: IngoingIntent,
  ) {
    this.logger.trace('Embedded: Dispatching ingoing intent', ingoingIntent);
    if (!this.embedded) {
      this.logger.warn('Embedded: Try handling ingoing intent, but not in embedded mode, ignoring');
      return;
    }
    this.ingoingIntent$.next(ingoingIntent);
  }

  private dispatchContextInfoEmbedded(
    contextInfo: ContextInfo,
  ): void {
    this.logger.trace('Embedded: Dispatching context info', contextInfo);
    if (!this.embedded) {
      this.logger.warn('Embedded: Try handling context info, but not in embedded mode, ignoring');
      return;
    }
    if (!contextInfo.properties) {
      this.logger.warn('Embedded: Try handling context info, properties missing, ignoring');
      return;
    }

    const properties = new Map<PropertyKey, ContextInfoProperty>(Object.entries(contextInfo.properties));
    this._contextInfo$.next(properties);
  }

  requestAccessToken(
    accessTokenRequest: EmitAccessTokenRequest,
  ): void {
    if (this.embedded) {
      this.requestAccessTokenEmbedded(accessTokenRequest);
    } else {
      this.logger.debug('could not request access token, not in embedded mode');
      accessTokenRequest.resultCallback({
        error: AccessTokenResultError.UNKNOWN,
        message: 'unknown',
        success: false,
      });
    }
  }

  private requestAccessTokenEmbedded(
    accessTokenRequest: EmitAccessTokenRequest,
  ): void {
    const shellIntegrationProxy = this.getShellIntegrationProxy();
    if (shellIntegrationProxy) {
      try {
        const result = shellIntegrationProxy.requestAccessToken(accessTokenRequest);
        accessTokenRequest.resultCallback(result);

        // INFO: Callback um auf neue Access Token zu reagieren
        if (result && result.success && result.accessToken) {
          for (const accessTokenListenersFunction of this.accessTokenListeners) {
            accessTokenListenersFunction(result.accessToken);
          }
        }

        this.logger.trace('Embedded: Access token request succeeded');
        return;
      } catch (error) {
        this.logger.warn('Embedded: Error during request access request', error);
      }
    }

    accessTokenRequest.resultCallback({
      success: false,
      error: AccessTokenResultError.UNKNOWN,
    });
    this.logger.warn('Standalone: Access token request failed');
  }

  handleOutgoingIntentRequestEmbedded(
    outgoingIntentRequest: EmitOutgoingIntentRequest,
  ): void {
    this.logger.debug('Handling outgoing intent in embedded mode', outgoingIntentRequest);

    if (this.embedded) {
      // TODO resultCallback
      // TODO logging
      return;
    }

    // TODO: Prüfen ob Intent für Embedded Verfügbar ist
    // TODO: Intent gegen Intent-Json Schema validieren
    // TODO: Intent an Embedded-Parent senden
    // TODO: Senden des Intents bestätigen

    const shellIntegrationProxy = this.getShellIntegrationProxy();
    if (shellIntegrationProxy) {
      try {
        const result = shellIntegrationProxy.handleOutgoingIntentRequest(outgoingIntentRequest);
        outgoingIntentRequest.resultCallback(result);

        return;
      } catch (e) {
        this.logger.warn('Could not handle outgoing intent request', e);
      }
    }
    outgoingIntentRequest.resultCallback({
      success: false,
    });
  }

  requestCloseEmbedded(
    emitCloseRequest: EmitCloseRequest,
  ): void {
    this.logger.trace('Embedded: Handling close request');

    if (!this.embedded) {
      // TODO Logging
      return;
    }

    const shellIntegrationProxy = this.getShellIntegrationProxy();
    if (!shellIntegrationProxy) {
      this.logger.warn('Embedded: Could not handle close request, shell integration proxy missing');
      return;
    }
    shellIntegrationProxy.requestClose({});
  }
}
